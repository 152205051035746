import settings from '../config.js';
import Cookies from 'js-cookie';

export default async function insertPoi(poiInfo){
    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/insertPoi`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
            },
            body: JSON.stringify(poiInfo)
        }
    );

    return await response.json();
}