import React, { createContext, useState, useEffect } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    const handleMediaQueryChange = (event) => {
      setShowSidebar(event.matches);
    };

    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
}, []);

  return (
    <DataContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </DataContext.Provider>
  );
};