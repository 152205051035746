import {Tooltip} from 'react-leaflet';

export default function StopTooltip({marker}) {
    const offset = marker.isPoi ? [-3, -15] : [0, -3];
    
    return (
        <Tooltip direction="top" offset={offset}>
            <div className='popup-container text-center fs-10'>
                <div>{marker.StartHourText} - {marker.EndHourText}</div>
                <div>{marker.Description}</div>
                <div>{marker.NextDistance} km</div>
            </div>
        </Tooltip>
    );
}