import Cookies from 'js-cookie';

const useAuth = () => {
    let isAuthenticated = false;
    const jwtAccessToken = Cookies.get('jwtAccessToken');
    if (jwtAccessToken)
        isAuthenticated = true;

    return isAuthenticated;
};

export default useAuth;