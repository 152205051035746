import { useState, useEffect, useRef, useContext } from "react";
import Map from "./map/Map.js";
import Sidebar from "./sidebar/Sidebar.js";
import refreshJwtAccessToken from "../../utils/api/refreshJwtAccessToken.js";
import { useNavigate, useSearchParams } from 'react-router-dom';
import settings from "../../utils/config.js";
import moment from 'moment';
import $ from 'jquery';
import vehiclesData from "../../utils/api/vehiclesData.js";
import routes from "../../utils/api/routes.js";
import { DataContext } from "../../providers/SidebarProvider.js";
import isMobileMode from "../../utils/isMobileMode.js";

export default function Station() {
    const { showSidebar, setShowSidebar } = useContext(DataContext);
    const navigate = useNavigate();
    const datepickerRef = useRef();
    const [searchParams] = useSearchParams();
    const truckId = searchParams.get('truckId');
    const [fleetFilterVisible, setFleetFilterVisible] = useState(showSidebar);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(3);
    const [dateFrom, setDateFrom] = useState(moment().format("DD/MM/YYYY 00:00"));
    const [dateTo, setDateTo] = useState(moment().format("DD/MM/YYYY 23:59"));
    const [loading, setLoading] = useState(false);
    const [allStops, setAllStops] = useState([]);
    const [displayStopsOnMap, setDisplayStopsOnMap] = useState([]);
    const [stopsAndPoint, setStopsAndPoint] = useState([]);
    const [route, setRoute] = useState({
        stops: [],
        points: []
    });
    const [filteredPoints, setFilteredPoints] = useState([]);
    const [showNoStopsModal, setShowNoStopsModal] = useState(false);

    const clearMapStopsAndPoints = () => {
        setDisplayStopsOnMap([]);
        setFilteredPoints([]);
        setRoute({
            stops: [],
            points: []
        });
    }

    const refreshAccessToken = async () => {
        try {
            await refreshJwtAccessToken();
        }
        catch {
            navigate('/login?reason=invalid_token');
        }
    }

    const fetchStops = async (vehicleId) => {
        try {
            setLoading(true);
            setShowSidebar(!isMobileMode());
            clearMapStopsAndPoints();
            const formattedDateFrom = moment(dateFrom, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm');
            const formattedDateTo = moment(dateTo, 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm');
            const routesData = await routes(vehicleId, formattedDateFrom, formattedDateTo, selectedDuration);
            routesData.stops = routesData.stops
                .filter((stop) => stop.Description !== "ΑΠΩΛΕΙΑ GPS" && stop.Description !== "ΑΠΩΛΕΙΑ ΤΡΟΦΟΔΟΣΙΑΣ")
                .map((stop, index) => ({
                ...stop,
                index: index + 1,
            }));

            setAllStops(routesData.stops);
            setDisplayStopsOnMap(routesData.stops);
            setStopsAndPoint(routesData);
            if (!routesData.stops || routesData.stops.length === 0)
                setShowNoStopsModal(true);
        }
        catch {
            navigate('/error');
        }
        finally {
            setLoading(false);
        }
    };

    function selectSingleStop(stopIndex) {
        clearMapStopsAndPoints();
        setShowSidebar(!isMobileMode());
        setDisplayStopsOnMap(allStops.filter((stop) => stop.index === stopIndex));
    }

    const fetchAllRoutes = async () => {
        clearMapStopsAndPoints();
        setShowSidebar(!isMobileMode());
        setRoute(stopsAndPoint);
    }

    const fetchSelectedRoute = async (sDate, eDate, stopIndex) => {
        clearMapStopsAndPoints();
        setShowSidebar(!isMobileMode());
        const formatDate = (date, format) => moment(date.replace("T", " "), format).format('YYYY/MM/DD HH:mm:ss');
        const formattedDateFrom = formatDate(sDate, 'YYYY-MM-DD HH:mm:ss');
        const formattedDateTo = eDate ? formatDate(eDate, 'YYYY-MM-DD HH:mm:ss') : formatDate(dateTo, 'DD/MM/YYYY HH:mm:ss');
        try {
            const response = await routes(selectedVehicle.value, formattedDateFrom, formattedDateTo, selectedDuration);
            response.stops = response.stops.map((stop, index) => ({
                ...stop,
                index: stopIndex + index
            }));

            setRoute(response);
        }
        catch {
            navigate('/error');
        }
    }

    function fetchStopMarkers() {
        clearMapStopsAndPoints();
        setShowSidebar(!isMobileMode());
        // Χρησιμοποιούμε setTimeout για να δώσουμε χρόνο στο React να κάνει πρώτα το reset
        setTimeout(() => {
            setDisplayStopsOnMap(allStops);
        }, 0);
    }

    const fetchVehicles = async () => {
        try {
            const data = await vehiclesData();
            const formattedOptions = data.map(vehicle => ({
                value: vehicle.TruckID,
                label: (
                    <div>
                        <img src={`${settings.vehicleIconUrl}/images/${vehicle.CatName}_blue_r.png`} alt={vehicle.Sign} />
                        <span className="ms-1">
                            {vehicle.Sign} - {vehicle.CategoryDescription}
                        </span>
                    </div>
                ),
                sign: vehicle.Sign
            }));

            setVehicles(formattedOptions);
        }
        catch (error) {
            navigate('/error');
        }
    }

    const handleDateChange = (start, end) => {
        setDateFrom(start.format('DD/MM/YYYY HH:mm'));
        setDateTo(end.format('DD/MM/YYYY HH:mm'));
    };

    useEffect(() => {
        fetchVehicles();
    }, []);

    useEffect(() => {
        setFleetFilterVisible(showSidebar);
    }, [showSidebar]);

    useEffect(() => {
        const intervalRefreshAccessToken = setInterval(async () => {
            refreshAccessToken();
        }, settings.refreshJwtTimeIntervalMilliseconds);

        return () => {
            clearInterval(intervalRefreshAccessToken);
        };

    }, [navigate]);

    useEffect(() => {
        $(datepickerRef.current).daterangepicker({
            timePicker: true,
            timePicker24Hour: true,
            startDate: moment(dateFrom, 'DD/MM/YYYY HH:mm'),
            endDate: moment(dateTo, 'DD/MM/YYYY HH:mm'),
            maxDate: moment().format("DD/MM/YYYY") + " 23:59",
            locale: {
                format: 'DD/MM/YYYY HH:mm'
            },
            ranges: {
                'Σήμερα': [moment().format("DD/MM/YYYY") + " 00:00", moment().format("DD/MM/YYYY") + " 23:59"],
                'Χθες': [moment().subtract(1, 'days').format("DD/MM/YYYY") + " 00:00", moment().subtract(1, 'days').format("DD/MM/YYYY") + " 23:59"],
                'Τελευταίες 2 μέρες': [moment().subtract(1, 'days').format("DD/MM/YYYY") + " 00:00", moment().format("DD/MM/YYYY") + " 23:59"]
            }
        }, handleDateChange);

        $('[data-range-key="Custom Range"]').text('Επιλογή διαστήματος');
    });

    useEffect(() => {
        if (datepickerRef.current) {
            $(datepickerRef.current).data('daterangepicker').setStartDate(moment(dateFrom, 'DD/MM/YYYY HH:mm'));
            $(datepickerRef.current).data('daterangepicker').setEndDate(moment(dateTo, 'DD/MM/YYYY HH:mm'));
        }
    }, [dateFrom, dateTo]);

    useEffect(() => {
        if (truckId && vehicles.length > 0) {
            const vehicle = vehicles.find(v => v.value === parseInt(truckId));
            if (vehicle) {
                setSelectedVehicle(vehicle);
                fetchStops(vehicle.value);
            }
        }
    }, [searchParams, vehicles]);

    return (
        <div className="d-flex">
            <div className={`filter ${!fleetFilterVisible ? 'd-none' : ''}`}>
                <Sidebar
                    vehicles={vehicles}
                    setVehicles={setVehicles}
                    selectedVehicle={selectedVehicle}
                    setSelectedVehicle={setSelectedVehicle}
                    selectedDuration={selectedDuration}
                    setSelectedDuration={setSelectedDuration}
                    allStops={allStops}
                    setAllStops={setAllStops}
                    loading={loading}
                    setLoading={setLoading}
                    showNoStopsModal={showNoStopsModal}
                    setShowNoStopsModal={setShowNoStopsModal}
                    dateFrom={dateFrom}
                    setDateFrom={setDateFrom}
                    dateTo={dateTo}
                    setDateTo={setDateTo}
                    fetchStops={fetchStops}
                    fetchStopMarkers={fetchStopMarkers}
                    selectSingleStop={selectSingleStop}
                    datepickerRef={datepickerRef}
                    fetchAllRoutes={fetchAllRoutes}
                    fetchSelectedRoute={fetchSelectedRoute}
                />
            </div>
            <div className="map-container">
                <Map
                    fleetFilterVisible={fleetFilterVisible}
                    setFleetFilterVisible={setFleetFilterVisible}
                    selectedVehicle={selectedVehicle}
                    fetchStops={fetchStops}
                    displayStopsOnMap={displayStopsOnMap}
                    routes={route}
                    setFilteredPoints ={setFilteredPoints}
                    filteredPoints={filteredPoints}
                />
            </div>
        </div>
    );
}