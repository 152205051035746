import {DivIcon} from 'leaflet';
import settings from './config';

export default function createStationIcon(isPoi, index) {
    if(isPoi){
        return new DivIcon ({
            html: `<img src="${settings.vehicleIconUrl}/images/star.png" /><div class="starIcon-centered divIcon">${index}</div>`,
            iconSize: [32, 32],
            iconAnchor: [16, 16],
            popupAnchor: [-4, -15],
            tooltipAnchor: [0, 0]
        });
    }
    else
        return new DivIcon ({
            html: `<img src="${settings.vehicleIconUrl}/images/circle.png" /><div class="circleIcon-centered divIcon">${index}</div>`,
            iconSize: [15, 14],
            iconAnchor: [8, 7],
            popupAnchor: [0, -2],
            tooltipAnchor: [0, 0]
        });
}