import React from 'react';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import Popup from './Popup';
import Tooltip from './Tooltip';

export default function Clusters ({displayVehicle, icons, handleClusterGroupMouseOver}) {
    return (
        <MarkerClusterGroup
            disableClusteringAtZoom={16}
            zoomToBoundsOnClick={true}
            maxClusterRadius={30}
            spiderfyOnMaxZoom={false}
            onmouseover={handleClusterGroupMouseOver}
        >
            {displayVehicle && displayVehicle.map((vehicle) => (
                <Marker
                    position={[parseFloat(vehicle.Latitude), parseFloat(vehicle.Longitude)]}
                    icon={icons[vehicle.TruckID] || new L.Icon({
                        iconUrl: 'default-icon-url.png',
                        iconSize: [32, 32]
                    })}
                    key={vehicle.TruckID}
                    sign={vehicle.Sign}
                >
                    <Tooltip vehicle={vehicle} />
                    <Popup vehicle={vehicle} />
                </Marker>
            ))}
        </MarkerClusterGroup>
    );
}