import settings from '../config.js';

export default async function user(userData){
    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/users`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userData)
        }
    );

    return await response.json();
}