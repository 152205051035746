import settings from '../config.js';
import Cookies from 'js-cookie';

export default async function routes(truckId, dateFrom, dateTo, minDuration){
    const params = new URLSearchParams({
        truckId: truckId.toString(),
        dateFrom,
        dateTo,
        minDurationMinutes: parseInt(minDuration)
    });

    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/routes?${params}`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
            }
        }
    );

    const result = await response.json();

    return {
        stops: result.result.Table1,
        points: result.result.Table
    };
}