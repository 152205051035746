import React from "react";
import {Tooltip} from 'react-leaflet';
import settings from '../../../utils/config.js';
import getDifferenceTime from '../../../utils/getDifferenceTime.js';

function formatDate(date) {
    const currDate = new Date();
    const padZero = (num) => num.toString().padStart(2, '0');
    
    const dateParts = date.split(" ");
    const [datePart, timePart] = dateParts;
    const [year, month, day] = datePart.split("-");
    const [hours, minutes] = timePart.split(":");

    const currDateYear = currDate.getFullYear();
    const currDateMonth = padZero(currDate.getMonth() + 1);
    const currDateDay = padZero(currDate.getDate());

    const formattedCurrDay = `${currDateDay}/${currDateMonth}/${currDateYear}`;
    const examinedCurrDay = `${day}/${month}/${year}`;

    const finalDate = (examinedCurrDay === formattedCurrDay)
        ? `${padZero(hours)}:${padZero(minutes)}`
        : `${examinedCurrDay} ${padZero(hours)}:${padZero(minutes)}`;

    return finalDate;
}

export default function VehicleTooltip({ vehicle }){
    let brokenAlertText = vehicle.AlertText.split("|");

    return (
        <Tooltip direction="top" permanent>
            <div className='popup-container fs-10'>
                {settings.showOnlyPlateSignOnVehicleLabelSetting && (
                    <div><b>{vehicle.Sign}</b></div>
                )}
                {settings.showLatestTelemetrySignalTimestampOnVehicleLabelSetting && getDifferenceTime(vehicle.sdate) > 0.08 && (
                    <div><b>{formatDate(vehicle.sdate)}</b></div>
                )}
                {brokenAlertText.length > 1 && (
                    <>
                        {settings.showEcoOnVehicleLabelSetting && vehicle.Eco >= 0 && vehicle.Eco < 100 && vehicle.Eco != null && !brokenAlertText[2].includes("19") && (
                            <div><b>ECO {vehicle.Eco}%</b></div>
                        )}
                        {settings.showTemperatureOnVehicleLabel && vehicle.LabelData && !brokenAlertText[2].includes("16") && (
                            <div><b>{vehicle.LabelData}</b></div>
                        )}
                    </>
                )}
                {settings.showBrokenAlertTextOnVehicleLabel && brokenAlertText[0] === "ALERT" && (
                    <div className='fw-bold text-danger'>{brokenAlertText[1]}</div>
                )}
                {settings.showExtraLabelDataOnVehicleLabel && vehicle.extraLabelData && (
                    <div><b>{vehicle.extraLabelData}</b></div>
                )}
            </div>
        </Tooltip>
    );
}