import Control from 'react-leaflet-custom-control';

export default function Supervision({allMarkersVisible, handleFocus}) {
    return (
        <Control>
            <input
                id="lockIcon"
                type="image"
                src={allMarkersVisible ? "/images/lock.png" : "/images/unlock.png"}
                alt={allMarkersVisible ? 'lock' : 'unlock'}
                className="supervision"
                onClick={handleFocus}
            />
        </Control>
    );
}