import React, {useRef} from "react";
import {Marker} from 'react-leaflet';
import createStationIcon from '../../../../utils/createStationIcon';
import Popup from "./Popup";
import Tooltip from "./Tooltip";

export default function StopMarker({marker, selectedVehicle, fetchStops}) {
    const markerRefs = useRef([]);
    return (
        <Marker
            position={[parseFloat(marker.Latitude), parseFloat(marker.Longitude)]}
            icon={createStationIcon(marker.isPoi,marker.index)}
            key={marker.index}
            ref={(ref) => markerRefs.current[marker.index] = ref}
            eventHandlers={{
                mouseover: () => {
                    if (markerRefs.current[marker.index]) {
                        markerRefs.current[marker.index].closePopup();
                        markerRefs.current[marker.index].openTooltip();
                    }
                },
                mouseout: () => {
                    if (markerRefs.current[marker.index])
                        markerRefs.current[marker.index].closeTooltip();
                },
                click: () => {
                    if (markerRefs.current[marker.index]) {
                        markerRefs.current[marker.index].closeTooltip();
                        markerRefs.current[marker.index].openPopup();
                    }
                }
            }}
        >
            <Popup selectedVehicle={selectedVehicle} fetchStops={fetchStops} marker={marker} />
            <Tooltip marker={marker}/>
        </Marker>
    );
}