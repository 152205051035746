import React from 'react';
import Table from 'react-bootstrap/Table';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import settings from '../../../utils/config';

export default function StopTable({stops, selectSingleStop, fetchSelectedRoute}) {
  let totalMinutes = 0;
  const totalDistance = stops.reduce((total, stop) => total + stop.NextDistance, 0);
  stops.forEach(element => {
    const [hours, minutes] = element.DurationText.split(':').map(Number);
    totalMinutes += (hours * 60) + minutes;
  });
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  return (
    <>
      <Table className='text-center mt-2'>
        <thead>
          <tr>
            <th>AA</th>
            <th>Χρόνος</th>
            <th>Διαδρομή</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Όλες</td>
            <td>{String(totalHours)}h {String(remainingMinutes)}min</td>
            <td>{totalDistance.toFixed(1)} km</td>
          </tr>
        </tbody>
      </Table>
      <div className='station'>
        {stops && stops.map((stop, index) => {
          let nextStop = (index === stops.length - 1) ? { ...stop, EndHour: null } : stops[index + 1];

          return (
            <React.Fragment key={stop.index}>
              <div className="border">
                <Row className="align-items-center pointer" onClick={() => selectSingleStop(stop.index)}>
                  <Col xs="auto" className='stop-icon-container'>
                    <span className='fw-bold'>{stop.index}.</span>
                    <img
                      src={`${settings.vehicleIconUrl}/images/stop.png`}
                      alt='stop'
                      className='ms-1'
                    />
                  </Col>
                  <Col className='stop-info'>
                    <div className="text-group">
                      <div className='fs-14 mb-1'>{stop.Description}</div>
                      <div className='d-flex'>
                        <span className='w-100px fs-15'>{stop.StartHourText} - {stop.EndHourText}</span>
                        <span className='stop-distance'>{stop.NextDistance} km</span>
                        {stop.NextDistance > 0 && (
                          <span className='ml-3px'>
                            <Button
                              variant="secondary"
                              className='station-route-button bgc-grey'
                              onClick={(e) => {
                                e.stopPropagation();
                                fetchSelectedRoute(stop.StartHour, nextStop.EndHour, stop.index)
                              }}
                            >
                              <img
                                className='station-arrow'
                                src={`${settings.vehicleIconUrl}/images/arrow-right.jpg`}
                                alt='route'
                              />
                            </Button>
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
}