import {  useContext } from "react";
import getDifferenceTime from '../../../utils/getDifferenceTime.js';
import settings from "../../../utils/config.js";
import { DataContext } from '../../../providers/SidebarProvider.js';
import isMobileMode from "../../../utils/isMobileMode.js";

function getStatusDuration(statusDuration){
  if(statusDuration > 0 && statusDuration < 60)
    return statusDuration + " δευτ.";
  else if(statusDuration >= 60 && statusDuration < 120)
    return parseInt(statusDuration/60) + " λεπτό";
  else if(statusDuration >=60 && statusDuration < 14400)
    return Math.round(statusDuration/60) + " λεπτά";
  else if(statusDuration >= 14400 && statusDuration < 172800)
    return Math.round(statusDuration/(60*60)) + " ώρες";
  else
  return Math.round(statusDuration/(60*60*24)) + " ημέρες";
}

function formatDate(inputDate) {
  const dateTime = new Date(inputDate);
  const currDate = new Date();

  // Έλεγχος αν η ημερομηνία του inputDate είναι ίδια με την τρέχουσα ημερομηνία
  if (dateTime.toLocaleDateString('en-GB') === currDate.toLocaleDateString('en-GB')) {
    const hours = ('0' + dateTime.getHours()).slice(-2);
    const minutes = ('0' + dateTime.getMinutes()).slice(-2);
    return `(${hours}:${minutes})`;
  }

  const [day, month, year] = dateTime.toLocaleDateString('en-GB').split('/');
  const formattedDatePart = `${day}/${month}`;
  const monthDiff = (currDate.getFullYear() - dateTime.getFullYear()) * 12 + (currDate.getMonth() - dateTime.getMonth());
  if (monthDiff < 12)
    return `(${formattedDatePart})`;

  return '';
}

export default function VehicleDetails ({node, selectedVehicles, setSelectedVehicles, selectedVehiclesFromNode, setSelectedVehiclesFromNode, vehicleStatus, setVehicleStatus, setAllMarkersVisible}){
  const { setShowSidebar } = useContext(DataContext);
  const showSpeed = getDifferenceTime(node.data.sDate) <= 12;
  const movingStatus = [1, 2].includes(node.data.status);
  const isSelected = selectedVehicles.includes(parseInt(node.id));
  const isFocus = vehicleStatus !== 0 ? isSelected : isSelected || selectedVehicles.length === 0;

  function handleSelectedVehicles(node) {
    const vehicleId = parseInt(node.id);
    if (selectedVehiclesFromNode)
      setSelectedVehicles([vehicleId]);
    else {
      const isVehicleSelected = selectedVehicles.includes(vehicleId);

      if (isVehicleSelected) {
        // If the vehicle is already selected and vehicleStatus is 0, remove it from the selection
        if (vehicleStatus === 0)
          setSelectedVehicles(prevSelectedVehicles => prevSelectedVehicles.filter(id => id !== vehicleId));
        else
          setSelectedVehicles([vehicleId]);
      } else {
        // If the vehicle is not selected
        if (vehicleStatus !== 0)
          setSelectedVehicles([vehicleId]);
        else
          setSelectedVehicles([...selectedVehicles, vehicleId]);
      }
    }

    setSelectedVehiclesFromNode(false);
    setVehicleStatus(0);
    setAllMarkersVisible(true);
    setShowSidebar(!isMobileMode());
  }

  const renderFocusIcon = () => (
    <img
      src={isFocus ? "./images/focus.png" : "./images/nofocus.png"}
      alt={isFocus ? "focus" : "nofocus"}
      className="icon"
    />
  );

  const renderSpeedOrDuration = () => {
    if (!showSpeed) return null;
    if (movingStatus) {
      return (
        <>
          {node.data.speed} km/h
          <span>
            <img
              style={{ transform: `rotate(-${node.data.direction}deg)`, marginTop: -5, marginLeft: 5 }}
              src={`${settings.vehicleIconUrl}/images/routearrowe.png`}
              alt="direction"
            />
          </span>
        </>
      );
    }
    return <span>{getStatusDuration(node.data.statusDuration)}</span>;
  };

  return (
    <div className="float-end d-flex">
      <div className="text-end me-2">
        {renderSpeedOrDuration()}
      </div>
      <div className="text-center me-1">
        <span className="mr-5px">{formatDate(node.data.sDate)}</span>
        <span onClick={() => handleSelectedVehicles(node)}>
          {renderFocusIcon()}
        </span>
      </div>
    </div>
  );
}