export default function Error() {
  return (
    <div className="container alert alert-danger text-center fw-bold mt-2">
      <p className="fs-2">Oops! Something went wrong.</p>
      <p>
        It seems we encountered an issue and cannot load the page you requested.
      </p>
    </div>
  );
}
