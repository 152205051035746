export default function setCookie(cookieName, value, isToken) {
  let date = new Date();
  date.setTime(date.getTime() + (360 * 24 * 60 * 60 * 1000));
  let expirationDate = date.toUTCString();

  if(isToken) {
    const jwtPayload = JSON.parse(window.atob(value.split('.')[1]));
    expirationDate = new Date(jwtPayload.exp * 1000).toUTCString();
  }

  document.cookie = `${cookieName}=${value}; expires=${expirationDate}; path=/`;
}