export default function NotFound() {
  return (
      <div className="container alert alert-danger text-center fw-bold mt-2">
        <p>Page Not Found (404)</p>
        <p>
          The page you are looking for cannot be found. You might have typed the
          address incorrectly, or the page may have been moved or deleted.
        </p>
      </div>
  );
}
