import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Spinner from 'react-bootstrap/Spinner';
import settings from '../utils/config.js';
import setCookie from '../utils/setCookie.js';
import user from '../utils/api/user.js'

export default function LoginForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [jwtErrorMessage, setJwtErrorMessage] = useState("");
  const [data, setData] = useState({
    username: "",
    password: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  useEffect(() => {
    if(searchParams.get('reason') === "invalid_token")
      setJwtErrorMessage(`Session timed out.<br/>Log in again.`);
  },[searchParams]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    setJwtErrorMessage("");
    setJwtErrorMessage("");
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    else {
      event.preventDefault();
      setLoading(true);
      const userData = {
        subdomain: settings.subdomain,
        username: data.username,
        password: data.password,
        identityAuth: settings.identityAuth
      };

      try {
        setErrorMessage("");
        const response = await user(userData);

        if (response.jwtAccessToken == null)
          setErrorMessage("Login failed. Please check your credentials and try again.");
        else {
          setCookie("jwtAccessToken", response.jwtAccessToken, true);
          setCookie("username", response.username, false);
          navigate("/liveTracking");
        }
      }
      catch (error) {
        setErrorMessage("There was an error with the login request. Please try again later.");
      } 
      finally {
        setLoading(false);
      }
    }

    setValidated(true);
  };

  return (
    <div className='login-form'>
      <div className="text-center">
        <img src='./images/bk-logo.png' alt='logo'/>
      </div>
      {jwtErrorMessage && (
        <div className="alert alert-danger fw-bold" dangerouslySetInnerHTML={{ __html: jwtErrorMessage }} />
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <InputGroup hasValidation>
            <FloatingLabel label="Username" className="mb-2">
              <Form.Control
                type="text"
                name="username"
                placeholder="Username"
                autoComplete="off"
                required
                value={data.username}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please fill out the field.
              </Form.Control.Feedback>
            </FloatingLabel>
          </InputGroup>
        </Row>
        <Row className="mb-1">
          <InputGroup hasValidation>
            <FloatingLabel label="Password" className="mb-2">
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                autoComplete="off"
                required
                value={data.password}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please fill out the field.
              </Form.Control.Feedback>
            </FloatingLabel>
          </InputGroup>
        </Row>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        {loading && (
          <div className="text-center mb-3">
            <Spinner animation="border" />
          </div>
        )}
        <button type="submit" className="w-100 btn btn-lg btn-outline-primary mt-2">
            Login
        </button>
      </Form>
    </div>
  );
}