import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { Modal, Button } from 'react-bootstrap';
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin, faRoute, faSearch } from '@fortawesome/free-solid-svg-icons';
import StopTable from "./StopTable.js";

export default function Sidebar({
    vehicles,
    selectedVehicle, setSelectedVehicle,
    selectedDuration, setSelectedDuration,
    allStops,
    loading,
    showNoStopsModal, setShowNoStopsModal,
    fetchStops,
    fetchStopMarkers,
    selectSingleStop,
    datepickerRef,
    fetchAllRoutes,
    fetchSelectedRoute
}) {
    const noOptionsMessage = () => 'Δεν βρέθηκαν αποτελέσματα';

    return (
        <>
            <div className="station-filter-container">
                <div className="station-filter">
                    <Select
                        isClearable={true}
                        isSearchable={true}
                        placeholder="Επιλέξτε όχημα..."
                        noOptionsMessage={noOptionsMessage}
                        options={vehicles}
                        value={selectedVehicle}
                        getOptionLabel={(option) => option.sign}
                        getOptionValue={(option) => option.value}
                        formatOptionLabel={(option) => option.label}
                        onChange={(option) => setSelectedVehicle(option)}
                        blurInputOnSelect={false}
                        autoFocus={false}
                        className="fs-14 mt-1"
                    />
                    <div className="d-flex mt-1">
                        <div className="w-290px">
                            <Form.Control type="text" className="pointer" placeholder="Datetime range" ref={datepickerRef} />
                        </div>
                        <div className="ms-2 w-70">
                            <Form.Select
                                value={selectedDuration}
                                onChange={e => setSelectedDuration(e.target.value)}
                            >
                                <option value="1">1'</option>
                                <option value="2">2'</option>
                                <option value="3">3'</option>
                                <option value="5">5'</option>
                                <option value="10">10'</option>
                                <option value="15">15'</option>
                                <option value="20">20'</option>
                                <option value="25">25'</option>
                                <option value="30">30'</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-1">
                        <button
                            className="btn btn-warning w-70px"
                            disabled={allStops.length === 0 || loading}
                            onClick={() => fetchAllRoutes()}
                            title="Εμφάνιση του πλήρους δρομολογίου"
                        >
                            <FontAwesomeIcon icon={faRoute} style={{color: 'white'}}/>
                        </button>
                        <button
                            className="btn btn-danger w-70px"
                            disabled={allStops.length === 0 || loading}
                            onClick={() => fetchStopMarkers()}
                            title="Εμφάνιση όλων των στάσεων"
                        >
                            <FontAwesomeIcon icon={faMapPin} />
                        </button>
                        <button
                            className="btn btn-success w-70px"
                            disabled={!selectedVehicle || loading}
                            onClick={() => fetchStops(selectedVehicle.value)}
                            title="Αναζήτηση"
                        >
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="spinner-wrapper">
                    <Spinner animation="border" />
                </div>
            ) : (
                allStops && allStops.length > 0 && (
                    <div className="mt-130px">
                        <StopTable
                            stops={allStops}
                            selectSingleStop={selectSingleStop}
                            fetchSelectedRoute={fetchSelectedRoute}
                        />
                    </div>
                )
            )}

            <Modal size='sm' show={showNoStopsModal} onHide={() => setShowNoStopsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='fs-20'>Μήνυμα απο εφαρμογή</Modal.Title>
                </Modal.Header>
                <Modal.Body>Δεν βρέθηκαν στάσεις για τα επιλεγμένα κριτήρια.</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowNoStopsModal(false)}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}