import React from 'react';
import Control from 'react-leaflet-custom-control';

export default function SidebarToggleButton ({fleetFilterVisible, handleArrow}) {
    return (
        <Control position="topleft">
            <input
                type="image"
                src={fleetFilterVisible ? "/images/left-arrow.png" : "/images/right-arrow.png"}
                alt={fleetFilterVisible ? 'left-arrow' : 'right-arrow'}
                title={fleetFilterVisible ? 'Collapse' : 'Expand'}
                className="filter-arrow"
                onClick={handleArrow}
            />
        </Control>
    );
}