import Control from 'react-leaflet-custom-control';
import settings from '../../../utils/config';

export default function AlertCategories({ alertCategories }) {
    return (
        <div className='alert-container'>
            {alertCategories.map((alertCategory) => (
                (alertCategory.isEnable === 1 && 
                    alertCategory.Unviewed > 0 && 
                    alertCategory.ID !== 11 && 
                    alertCategory.ID !== 16
                ) ? (
                    <div
                        title={alertCategory.displayNameGR}
                        className='alert-notification'
                        key={alertCategory.ID}
                    >
                        <img
                            src={`${settings.vehicleIconUrl}/images/alert-icons/${alertCategory.Icon}.png`}
                            alt={alertCategory.displayNameGR}
                            className='w-32'
                        />
                        <span className="position-absolute top-0 translate-middle badge rounded-pill bg-danger fs-10">
                            {alertCategory.Unviewed}
                        </span>
                    </div>
                ) : null
            ))}
        </div>
    );
}