import {useState} from "react";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import VehicleTree from './VehicleTree.js';

export default function SideBar({fleet, loading, selectedVehicles, setSelectedVehicles, vehicleStatus, setVehicleStatus, setAllMarkersVisible}){
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        team: true,
        category: true,
        order: ['team', 'category']
    });

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters(prevFilters => {
            const newOrder = [...prevFilters.order];
            if (checked) {
                if (!newOrder.includes(name)) newOrder.push(name);
            } else {
                const index = newOrder.indexOf(name);
                if (index > -1) newOrder.splice(index, 1);
            }
            return {
                ...prevFilters,
                [name]: checked,
                order: newOrder
            };
        });
    };

    if(loading)
        return <div className="spinner-wrapper">
                    <Spinner animation="border" />
               </div>;

    return(
        <>
            <div>
                <div className="fleet-filter">
                    <div className="col-3 mt-2 ms-2">
                        <Form.Check
                            inline
                            label="Ομάδα"
                            name="team"
                            type="checkbox"
                            id="team"
                            checked={filters.team}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="col-3 mt-2">
                        <Form.Check
                            inline
                            label="Κατηγορία"
                            name="category"
                            type="checkbox"
                            id="category"
                            checked={filters.category}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                    <div className="col-5 mt-1 ml-20">
                        <Form.Control
                            type="email"
                            className="h-35"
                            placeholder="Αναζήτηση..."
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <VehicleTree
                    fleetData={fleet}
                    searchTerm={searchTerm}
                    filters={filters}
                    selectedVehicles={selectedVehicles}
                    setSelectedVehicles={setSelectedVehicles}
                    vehicleStatus={vehicleStatus}
                    setVehicleStatus={setVehicleStatus}
                    setAllMarkersVisible={setAllMarkersVisible}
                />
            </div>
        </>
    );
}