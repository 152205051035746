import React from "react";
import {Popup} from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import settings from '../../../utils/config.js';

export default function VehiclePopup({ vehicle }) {
    const navigate = useNavigate();
    const iconSize = '20px';
    const imgSrc = src => `${settings.vehicleIconUrl}/images/${src}`;
    const convertNullToZero = value => value ?? 0;
    const arrowColor = (eco) => {
        if (eco >= 0 && eco < 13) return "#e6e600";
        if (eco >= 13 && eco < 26) return "orange";
        if (eco >= 26 && eco < 38) return "#ff3300";
        if (eco >= 38) return "red";
        return "#000";
    };

    const handleStopsClick = () => {
        navigate(`/station?truckId=${vehicle.TruckID}`);
    };
    
    return (
        <Popup>
            <div className='popup-container'>
                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td><img width={iconSize} src={imgSrc('bk_logo.ico')} alt='logo' /></td>
                                <td className='fs-12 fw-bold padding-2'>
                                    {vehicle.CompanyDescription}<br />{vehicle.Sign}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='border-top-1'>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><img width={iconSize} src={imgSrc('konter.png')} alt='speed' /></td>
                                        <td className='fs-12 fst-italic'>
                                            &nbsp;{vehicle.speed} km/h
                                        </td>
                                    </tr>
                                    {vehicle.DriverName && (
                                        <tr>
                                            <td><img width={iconSize} src={imgSrc('drivers-icon.png')} alt='driver' /></td>
                                            <td className='fs-12 fst-italic'>
                                                &nbsp;{vehicle.DriverName}
                                            </td>
                                        </tr>
                                    )}
                                    {vehicle.DriverPhoneNumber && (
                                        <tr>
                                            <td><img width="15px" src={imgSrc('phone-call.png')} alt='phone' /></td>
                                            <td className='fs-12 fst-italic'>
                                                &nbsp;{vehicle.DriverPhoneNumber}
                                            </td>
                                        </tr>
                                    )}
                                    {vehicle.Address && (
                                        <tr>
                                            <td><img width="17px" src={imgSrc('location.png')} alt='location' /></td>
                                            <td className='fs-12 fst-italic'>
                                                &nbsp;{vehicle.Address}
                                            </td>
                                        </tr>
                                    )}
                                    {vehicle.Eco >= 0 && vehicle.Eco < 100 && (
                                        (vehicle.Acceleration_Eco < 100 && vehicle.Decceleration_Eco === 100) || (vehicle.Acceleration_Eco < vehicle.Decceleration_Eco) ? (
                                            <tr>
                                                <td><img width={20} src={imgSrc('eco.png')} alt='eco' /></td>
                                                <td className='fs-12 text-center d-flex ml-40'>
                                                    <span>{vehicle.Eco}%</span>
                                                    <div className='mt-3px'>
                                                        <div className='eco-bar mt-3px' style={{
                                                            width: `${parseInt((100 - vehicle.Acceleration_Eco)/2)}px`,
                                                            background: arrowColor(parseInt((100 - vehicle.Acceleration_Eco)/2))
                                                        }}></div>
                                                        <div className='eco-arrow' style={{
                                                            borderLeft: `10px solid ${arrowColor(parseInt((100 - vehicle.Acceleration_Eco)/2))}`,
                                                            marginLeft: `${parseInt((100 - vehicle.Acceleration_Eco)/2)}px`
                                                        }}></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ) : (
                                            (vehicle.Acceleration_Eco === 100 && vehicle.Decceleration_Eco < 100) || (vehicle.Acceleration_Eco > vehicle.Decceleration_Eco) ? (
                                                <tr>
                                                    <td><img width={20} src={imgSrc('eco.png')} alt='eco' /></td>
                                                    <td className='fs-12 text-center d-flex ml-25'>
                                                        <div className='mt-3px'>
                                                            <div className='eco-arrow' style={{
                                                                transform: 'rotate(180deg)',
                                                                borderLeft: `10px solid ${arrowColor(parseInt((100 - vehicle.Decceleration_Eco)/2))}`
                                                            }}></div>
                                                            <div className='eco-bar ml-10 mt-7' style={{
                                                                width: `${parseInt((100 - vehicle.Decceleration_Eco)/2)}px`,
                                                                background: arrowColor(parseInt((100 - vehicle.Decceleration_Eco)/2)),
                                                            }}></div>
                                                        </div>
                                                        <span>{vehicle.Eco}%</span>
                                                    </td>
                                                </tr>
                                            ) : null
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className='border-top-1'>
                            <div className='fs-12'>
                                <b>Σύνολο: </b>
                                {Math.round(convertNullToZero(vehicle.DayDistance))} km ({Math.round(convertNullToZero(vehicle.TotalDistance))} km),
                                ECO {Math.round(convertNullToZero(vehicle.DayEco))}%,<br />
                                Υπέρβ.Ταχ: {convertNullToZero(vehicle.DayOverSpeedCounter)} ({parseInt(convertNullToZero(vehicle.DayMaxSpeed))} km/h),<br />
                                Υπέρβ.Ρελ: {convertNullToZero(vehicle.DayStandByCounter)} ({convertNullToZero(vehicle.DayStandByDuration)}’)<br />
                                <div className='stop-link' onClick={handleStopsClick}>
                                    Στάσεις: {convertNullToZero(vehicle.DayStationCounter)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}