import { Route, Routes } from "react-router-dom";
import LiveTracking from "../pages/LiveTrackingPage.jsx";
import Station from "../pages/StationPage.jsx";
import Login from "../pages/LoginPage.jsx";
import NotFound from "../pages/NotFoundPage.jsx";
import Error from "../pages/ErrorPage.jsx";
import { DataProvider } from "../providers/SidebarProvider.js";
import Navbar from "./MainNavbar.js";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hook/userAuth.js";
import ProtectedRoute from "../hook/protectedRoutes.js";

export default function AppRoutes() {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();
  useEffect(() => {
    if (isAuthenticated !== true)
      navigate("/login");
  }, [isAuthenticated, navigate]);

  return (
    <DataProvider>
      {isAuthenticated && <Navbar />}
      <Routes>
        <Route path="/" element={<ProtectedRoute><LiveTracking /></ProtectedRoute>} />
        <Route path="/login" element={<Login />} />
        <Route path="/liveTracking" element={<ProtectedRoute><LiveTracking /></ProtectedRoute>} />
        <Route path="/station" element={<ProtectedRoute><Station /></ProtectedRoute>} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </DataProvider>
  );
}
