import { useState, useContext } from 'react';
import { Link, useLocation } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faRoute, faUser, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../providers/SidebarProvider.js';
import isMobileMode from '../utils/isMobileMode.js';

export default function MainNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showUserProfilModal, setShowUserProfilModal] = useState(false);
  const { setShowSidebar } = useContext(DataContext);

  const handleLogout = () => {
    Cookies.remove('jwtAccessToken');
    Cookies.remove('username');
    Cookies.remove('mapLayer');
    navigate('/');
  };

  const isActive = (path) => location.pathname === path;
  const handleShowLogoutModal = () => setShowLogoutModal(true);
  const handleCloseLogoutModal = () => setShowLogoutModal(false);
  const handleShowUserProfilModal = () => setShowUserProfilModal(true);
  const handleCloseUserProfilModal = () => setShowUserProfilModal(false);

  const handleLiveTracking = () => {
    if (location.pathname === '/liveTracking') {
      if(isMobileMode())
        setShowSidebar(prev => !prev);
    }
    else {
      if(isMobileMode())
        setShowSidebar(false);

      navigate('/liveTracking');
    }
  };

  const handleStation = () => {
    if(location.pathname === '/station') {
      if(isMobileMode())
        setShowSidebar(prev => !prev);
    }
    else{
      if(isMobileMode())
        setShowSidebar(false);

      navigate('/station');
    }
  };

  return (
    <>
      <Navbar bg="dark" data-bs-theme="dark" className='nav-container'>
        <Container fluid>
          <Nav className="me-auto gap-2">
            <Nav.Link
              as={Link}
              to="/liveTracking"
              active={isActive('/liveTracking')}
              className="d-flex flex-column align-items-center"
              onClick={handleLiveTracking}
            >
              <FontAwesomeIcon className='fs-18 text-white' icon={faCar}/>
              <span className='d-none d-md-block'>Στόλος</span>
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/station"
              active={isActive('/station')}
              className="d-flex flex-column align-items-center"
              onClick={handleStation}
            >
              <FontAwesomeIcon className='fs-18 text-white' icon={faRoute}/>
              <span className='d-none d-md-block'>Στάσεις</span>
            </Nav.Link>
          </Nav>
          <Navbar.Brand className="mx-auto fw-bold fs-25">BK Telematics</Navbar.Brand>
          <Nav className="ms-auto gap-2">
            <Nav.Link className="d-flex flex-column align-items-center" onClick={handleShowUserProfilModal}>
              <FontAwesomeIcon className='fs-18 text-white' icon={faUser}/>
              <span className='d-none d-md-block'>{Cookies.get('username')}</span>
            </Nav.Link>
            <span className="nav-link pointer d-flex flex-column align-items-center" onClick={handleShowLogoutModal}>
              <FontAwesomeIcon className='fs-18 text-white' icon={faSignOut}/>
              <span className='d-none d-md-block'>Αποσύνδεση</span>
            </span>
          </Nav>
        </Container>
      </Navbar>

      <Modal size='sm' show={showUserProfilModal} onHide={handleCloseUserProfilModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fs-20'>Προφίλ χρήστη</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Όνομα χρήστη: <span className='fw-bold'>{Cookies.get('username')}</span></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleCloseUserProfilModal}>
            Κλείσιμο
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size='sm' show={showLogoutModal} onHide={handleCloseLogoutModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fs-20'>Αποσύνδεση χρήστη</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Θέλετε να αποσυνδεθείτε;</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleLogout}>
            Αποσύνδεση
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}