import Cookies from 'js-cookie';
import settings from '../config.js';

export default async function alertCategories(){
    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/alerts/categories`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
            }
        }
    );

    const alertCategories = await response.json();

    return alertCategories.result.Table;
}
