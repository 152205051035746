import Control from 'react-leaflet-custom-control';
import settings from '../../../utils/config';

export default function Counters({selectedVehicleStatus, notMovingVehicles, movingVehicles, overspeedVehicles, reladiVehicles, workVehicles, outOfOrderVehicles}) {
    return (
        <Control position='bottomleft'>
            <div className='counters'>
                <div className='circle notMovingCounter' onClick={() => selectedVehicleStatus(settings.notMoving)}>
                    <span className="text-shadow">{notMovingVehicles}</span>
                </div>
                <div className='circle movingCounter' onClick={() => selectedVehicleStatus(settings.moving)}>
                    <span className="text-shadow">{movingVehicles}</span>
                </div>
                <div className='circle overSpeedCounter' onClick={() => selectedVehicleStatus(settings.overSpeed)}>
                    <span className="text-shadow">{overspeedVehicles}</span>
                </div>
                <div className='circle reladiCounter' onClick={() => selectedVehicleStatus(settings.reladi)}>
                    <span className="text-shadow">{reladiVehicles}</span>
                </div>
                <div className='circle workCounter' onClick={() => selectedVehicleStatus(settings.work)}>
                    <span className="text-shadow">{workVehicles}</span>
                </div>
                <div className='circle outOfOrderCounter' onClick={() => selectedVehicleStatus(settings.outOfOrder)}>
                    <span className="text-shadow">{outOfOrderVehicles}</span>
                </div>
            </div>
        </Control>
    );
}