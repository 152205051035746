import Cookies from 'js-cookie';
import settings from '../config.js';

export default async function fleetData(){
    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/fleetData`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
            }
        }
    );

    var fleet = await response.json();
    const result = {
        companies: fleet.result.Table,
        categories: fleet.result.Table1,
        vehicles: fleet.result.Table3,
        combined: fleet.result.Table2
    };

    return result;
}