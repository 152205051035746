import React, {useRef} from "react";
import {Marker, Tooltip, Popup} from 'react-leaflet';
import createStationIcon from "../../../../utils/createStationIcon";

export default function RouteStation ({marker}) {
    const markerRefs = useRef([]);
    const offset = marker.isPoi ? [-3, -15] : [0, -3];

    return (
        <Marker
            position={[parseFloat(marker.Latitude), parseFloat(marker.Longitude)]}
            icon={createStationIcon(marker.isPoi,marker.index)}
            zIndexOffset={1000}
            key={marker.index}
            ref={(ref) => markerRefs.current[marker.index] = ref}
            eventHandlers={{
                mouseover: () => {
                    if (markerRefs.current[marker.index]) {
                        markerRefs.current[marker.index].closePopup();
                        markerRefs.current[marker.index].openTooltip();
                    }
                },
                mouseout: () => {
                    if (markerRefs.current[marker.index])
                        markerRefs.current[marker.index].closeTooltip();
                },
                click: () => {
                    if (markerRefs.current[marker.index]) {
                        markerRefs.current[marker.index].closeTooltip();
                        markerRefs.current[marker.index].openPopup();
                    }
                }
            }}
        >
            <Popup>
                <div className='popup-container text-center fs-10'>
                    <div>{marker.StartHourText} - {marker.EndHourText}</div>
                    <div>{marker.Description}</div>
                    <div>{marker.NextDistance} km</div>
                </div>
            </Popup>
            <Tooltip direction="top" offset={offset}>
                <div className='popup-container text-center fs-10'>
                    <div>{marker.StartHourText} - {marker.EndHourText}</div>
                    <div>{marker.Description}</div>
                    <div>{marker.NextDistance} km</div>
                </div>
            </Tooltip>
        </Marker>
    )
}