import {Icon} from 'leaflet';
import getDifferenceTime from './getDifferenceTime.js';
import settingsVariables from './config.js';

function getVehicleIconPath(vehicle) {
    const direction = vehicle.direction;
    const status = vehicle.status;
    const currentTimeDifference = getDifferenceTime(vehicle.sdate);
    const iconDirection = (direction >= 91 && direction <= 270) ? "l" : "r";
    const statusColors = {
        1: "_red_",
        2: "_blue_",
        3: "_green_",
        4: "_yellow_",
        5: "_orange_"
    };

    const carState = (currentTimeDifference >= 10) ? "_white_" : statusColors[status];
    const iconPath = `${settingsVariables.vehicleIconUrl}/images/${vehicle.CatName}${carState}${iconDirection}.png`;

    return iconPath;
}

function getIconDimensions(imagePath) {
    return new Promise((resolve) => {
        const dummyDimensions = { width: 0, height: 0 };
        const storedDimensions = localStorage.getItem(imagePath);
        if (storedDimensions) {
            resolve(JSON.parse(storedDimensions));
        } else {
            const img = new Image();
            img.onload = function() {
                const dimensions = {
                    width: this.width,
                    height: this.height
                };
                localStorage.setItem(imagePath, JSON.stringify(dimensions));
                resolve(dimensions);
            };
            img.onerror = function() {
                resolve(dummyDimensions);
            };

            img.src = imagePath;

            resolve(JSON.stringify({"width": 0, "height": 0}));
        }
    });
}
export default async function getVehicleIcon(vehicle) {
    const iconUrl = getVehicleIconPath(vehicle);
    try
    {
        const iconDimensions = await getIconDimensions(iconUrl);
        return new Icon({
            iconUrl: iconUrl,
            iconSize: [iconDimensions.width, iconDimensions.height],
            iconAnchor: [Math.ceil(iconDimensions.width / 2), Math.ceil(iconDimensions.height / 2)],
            popupAnchor: [0, 0],
            tooltipAnchor: [0, -iconDimensions.height / 2]
        });
    }
    catch
    {
        return new Icon({
            iconUrl: iconUrl,
            iconSize: [0, 0],
            iconAnchor: [0, 0],
            popupAnchor: [0, 0],
            tooltipAnchor: [0, 0]
        });
    }
}