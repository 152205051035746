import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect } from "react";
import { MapContainer } from 'react-leaflet';
import setCookie from '../../../utils/setCookie.js';
import Cookies from 'js-cookie';
import MapLayers from '../../MapLayers.js';
import SidebarToggleButton from '../../SidebarToggleButton.js';
import { latLngBounds } from 'leaflet';
import StopMarker from './stop/Marker.js';
import RouteStation from './route/Marker.js';
import RoutePoint from './route/Point.js';

export default function Map({ fleetFilterVisible, setFleetFilterVisible, selectedVehicle, fetchStops, displayStopsOnMap, routes, setFilteredPoints, filteredPoints }) {
    const [map, setMap] = useState(null);
    const [activeLayer, setActiveLayer] = useState('Osm');

    useEffect(() => {
        const mapLayer = Cookies.get('mapLayer');
        if (mapLayer)
            setActiveLayer(mapLayer);
    }, []);

    useEffect(() => {
        let markerBounds = latLngBounds([]);
        if (routes.stops.length > 0 || routes.points.length > 0) {
            if (routes.stops.length > 0) {
                routes.stops.forEach(marker => {
                    markerBounds.extend([marker.Latitude, marker.Longitude]);
                });
                map.flyToBounds(markerBounds, { duration: 0.3 });
            }
            if (routes.points.length > 0) {
                routes.points.forEach(marker => {
                    markerBounds.extend([marker.Latitude, marker.Longitude]);
                });
                map.flyToBounds(markerBounds, { duration: 0.3 });
            }
        } 
        else {
            if (displayStopsOnMap.length > 0) {
                displayStopsOnMap.forEach(marker => {
                    markerBounds.extend([marker.Latitude, marker.Longitude]);
                });
                map.flyToBounds(markerBounds, { duration: 0.3 });
            }
        }
    }, [displayStopsOnMap, routes, map]);

    useEffect(() => {
        if (!map) return;

        map.on('zoomend', () => {
            if (routes.points.length === 0)
                setFilteredPoints([]);

            filterPoints();
        });

        map.on("baselayerchange", e => {
            setCookie("mapLayer", e.name, false);
        });
    }, [map, routes.points, displayStopsOnMap]);

    const filterPoints = () => {
        if (!map || routes.points.length === 0) return;
        let filtered = [];

        routes.points.forEach((point1, index) => {
            const point1Pixels = map.project([point1.Latitude, point1.Longitude]);
            if(index === 0)
                filtered.push(point1);
            else{
                const point2Pixels = map.project([filtered[filtered.length -1].Latitude, filtered[filtered.length -1].Longitude]);
                if(point1Pixels.distanceTo(point2Pixels) > 30)
                    filtered.push(point1);
            }
        });

        setFilteredPoints(filtered);
    };

    function handleArrow() {
        if (fleetFilterVisible)
            setFleetFilterVisible(false);
        else
            setFleetFilterVisible(true);

        setTimeout(function () { map.invalidateSize() }, 10);
    }

    return (
        <div>
            <MapContainer center={[37.983810, 23.727539]} zoom={6} maxZoom={18} minZoom={3} ref={setMap} attributionControl={false}>
                <SidebarToggleButton
                    fleetFilterVisible={fleetFilterVisible}
                    handleArrow={handleArrow}
                />
                <MapLayers
                    activeLayer={activeLayer}
                />
                {displayStopsOnMap && displayStopsOnMap.map((marker) => (
                    <StopMarker
                        marker={marker}
                        selectedVehicle={selectedVehicle}
                        fetchStops={fetchStops}
                        key={marker.index}
                    />
                ))}

                {routes.stops.length > 0 && routes.stops.map((stop) => (
                    <RouteStation
                        marker={stop}
                        key={stop.index}
                    />
                ))}

                {filteredPoints.length > 0 && filteredPoints.map((point, index) => (
                    <RoutePoint
                        marker={point}
                        index={index}
                        key={index}
                    />
                ))}
            </MapContainer>
        </div>
    );
}
