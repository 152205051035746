import { useEffect, useState, useRef, useContext } from "react";
import SideBar from "./sidebar/SideBar.js";
import Map from "./map/Map.js";
import { useNavigate } from 'react-router-dom';
import refreshJwtAccessToken from "../../utils/api/refreshJwtAccessToken.js";
import fleetData from "../../utils/api/fleetData.js";
import settings from "../../utils/config.js";
import vehiclesData from "../../utils/api/vehiclesData.js";
import { DataContext } from "../../providers/SidebarProvider.js";
import alertCategoriesData from "../../utils/api/alertCategories.js";

export default function LiveTracking() {
    const { showSidebar } = useContext(DataContext);
    const navigate = useNavigate();
    const [fleetFilterVisible, setFleetFilterVisible] = useState(showSidebar);
    const [allMarkersVisible, setAllMarkersVisible] = useState(true);
    const [loading, setLoading] = useState(true);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [vehicleStatus, setVehicleStatus] = useState(0);
    const [requestCounter, setRequestCounter] = useState(0);
    const intervalVehicleDetaillsRef = useRef(null);
    const [alertCategories, setAlertCategories] = useState([]);
    const [fleetInfo, setFleetInfo] = useState({
        companies: [],
        categories: [],
        vehicles: []
    });

    const refreshAccessToken = async () => {
        try {
            await refreshJwtAccessToken();
        }
        catch {
            navigate('/login?reason=invalid_token');
        }
    }

    const fetchFleetData = async () => {
        try {
            setFleetInfo(await fleetData());
        }
        catch {
            navigate('/error');
        }
        finally {
            setLoading(false);
        }
    };

    const fetchMapVehicles = async () => {
        try {
            setVehicles(await vehiclesData());
        }
        catch {
            navigate('/error');
        }
    }

    const fetchAlertCategories = async () => {
        try {
            setAlertCategories(await alertCategoriesData());
        }
        catch {
            navigate('/error');
        }
    }

    const checkHitsAndRefresh = () => {
        setRequestCounter(prev => {
            if (prev >= settings.requestLimit) {
                alert("Είστε ακόμα εδώ;");
                return 0;
            }
            else
                return prev + 1;
        });
    };

    const startDataRefreshCycle = () => {
        checkHitsAndRefresh();
        fetchFleetData();
        fetchMapVehicles();
        if(settings.showAlerts)
            fetchAlertCategories();
        intervalVehicleDetaillsRef.current = setInterval(() => {
            checkHitsAndRefresh();
            fetchFleetData();
            fetchMapVehicles();
            if(settings.showAlerts)
                fetchAlertCategories();
        }, settings.refreshTimeIntervalMilliseconds);
    }

    useEffect(() => {
        setFleetFilterVisible(showSidebar);
    }, [showSidebar]);

    useEffect(() => {
        const intervalRefreshAccessToken = setInterval(async () => {
            refreshAccessToken();
        }, settings.refreshJwtTimeIntervalMilliseconds);

        return () => {
            clearInterval(intervalVehicleDetaillsRef.current);
            clearInterval(intervalRefreshAccessToken);
        };

    }, [navigate]);

    useEffect(() => {
        startDataRefreshCycle();
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                clearInterval(intervalVehicleDetaillsRef.current);
                setRequestCounter(0);
            }
            else
                startDataRefreshCycle();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            clearInterval(intervalVehicleDetaillsRef.current);
        };
    }, []);

    return (
        <div className="d-flex">
            <div className={`filter ${!fleetFilterVisible ? 'd-none' : ''}`}>
                <SideBar
                    fleet={fleetInfo}
                    loading={loading}
                    selectedVehicles={selectedVehicles}
                    setSelectedVehicles={setSelectedVehicles}
                    vehicleStatus={vehicleStatus}
                    setVehicleStatus={setVehicleStatus}
                    setAllMarkersVisible={setAllMarkersVisible}
                />
            </div>
            <div className="map-container">
                <Map
                    fleetFilterVisible={fleetFilterVisible}
                    setFleetFilterVisible={setFleetFilterVisible}
                    vehiclesData={vehicles}
                    selectedVehicles={selectedVehicles}
                    setSelectedVehicles={setSelectedVehicles}
                    vehicleStatus={vehicleStatus}
                    setVehicleStatus={setVehicleStatus}
                    allMarkersVisible={allMarkersVisible}
                    setAllMarkersVisible={setAllMarkersVisible}
                    alertCategories={alertCategories}
                />
            </div>
        </div>
    );
}