import {  useContext } from "react";
import getDifferenceTime from '../../../utils/getDifferenceTime.js';
import settings from "../../../utils/config.js";
import VehicleDetails from './VehicleDetails.js';
import { DataContext } from '../../../providers/SidebarProvider.js';
import isMobileMode from "../../../utils/isMobileMode.js";

function getVehicleStatusIcon(status, date){
  const statusIcons = {
    1: "overSpeedStatus",
    2: "movingStatus",
    3: "notMovingStatus",
    4: "workingStatus",
    5: "reladiStatus"
  };

  if (getDifferenceTime(date) > 12)
    return "defaultStatus";

  return statusIcons[status] || "defaultStatus";
}

const getLeafNodesCount = (node) => {
    if (!node.children) return 1;
    return node.children.reduce((acc, child) => acc + getLeafNodesCount(child), 0);
};

export default function TreeNode({ node, style, filters,selectedVehicles, setSelectedVehicles, selectedVehiclesFromNode, setSelectedVehiclesFromNode, vehicleStatus, setVehicleStatus, setAllMarkersVisible }) {
  const { setShowSidebar } = useContext(DataContext);
  const handleToggle = () => {
    node.toggle();
  };

  function createVehicleStackFromNodes(vehicleSelections){
    setSelectedVehicles(prevSelectedVehicles => {
      const selectedSet = new Set(prevSelectedVehicles);
      vehicleSelections.forEach(id => {
        if (selectedSet.has(id))
          selectedSet.delete(id);
        else
          selectedSet.add(id);
      });

      return Array.from(selectedSet);
    });

    setShowSidebar(!isMobileMode());
  }

  function createVehicleStackFromGrandNodes(vehicleSelections){
    setSelectedVehicles(prevSelectedVehicles => {
      const prevSet = new Set(prevSelectedVehicles);
      const selectionsSet = new Set(vehicleSelections);
      const allSelectionsExist = [...selectionsSet].every(id => prevSet.has(id));
      if (allSelectionsExist)
        vehicleSelections.forEach(id => prevSet.delete(id));
      else
        vehicleSelections.forEach(id => prevSet.add(id));

      return Array.from(prevSet);
    });
    setShowSidebar(!isMobileMode());
  }

  function handleSelectedVehiclesFromNodes(node) {
    setSelectedVehiclesFromNode(true);
    if(!selectedVehiclesFromNode || node.level=== 0 || vehicleStatus !== 0)
      setSelectedVehicles([]);

    if(filters.order.length === 2 && node.level !== 0){
      if(node.level === 1)
        createVehicleStackFromGrandNodes(node.data.children.flatMap(child => child.children.map(grandchild => parseInt(grandchild.id))));
      if(node.level === 2)
        createVehicleStackFromNodes(node.data.children.map(child => parseInt(child.id)));
    }
    else if(filters.order.length === 1  && node.level === 1)
      createVehicleStackFromNodes(node.data.children.map(child => parseInt(child.id)));

    setVehicleStatus(0);
    setAllMarkersVisible(true);
    setShowSidebar(!isMobileMode());
  }

  function handleOneSelectedVehicle(node) {
    if(node.isLeaf){
      setSelectedVehiclesFromNode(false);
      setSelectedVehicles([parseInt(node.id)]);
      setVehicleStatus(0);
      setAllMarkersVisible(true);
    }
    setShowSidebar(!isMobileMode());
  }

  const leafNodesCount = node.isLeaf ? 0 : getLeafNodesCount(node);

  return (
    <div>
      <span style={style} onClick={handleToggle}>
        {node.isLeaf ? (
            ""
          ) : node.isOpen ? (
            <img src="images/collapse.png" alt="open" />
          ) : (
            <img src="images/expand.png" alt="close" />
        )}
      </span>

      <span>
        {node.isLeaf && (
          <img
            src={`/images/${getVehicleStatusIcon(node.data.status, node.data.sDate)}.png`}
            alt="status"
          />
        )}

        {node.data.vehicleIcon !== undefined && (
          <img
            className="ms-1"
            src={`${settings.vehicleIconUrl}/images/${node.data.vehicleIcon}_white_r.png`}
            alt="vehicle"
          />
        )}

        <span> </span>

        <span
          className="pointer sign"
          onClick={() => handleOneSelectedVehicle(node)}
        >
          {node.data.name}
        </span>
      </span>

      {node.children ? (
        <span
          className="badge rounded-pill bg-secondary float-end me-1 pointer leafNodeCount"
          onClick={() => handleSelectedVehiclesFromNodes(node)}
        >
          {leafNodesCount}
        </span>
        ) : (
        <VehicleDetails
          node={node}
          selectedVehicles={selectedVehicles}
          setSelectedVehicles={setSelectedVehicles}
          selectedVehiclesFromNode={selectedVehiclesFromNode}
          setSelectedVehiclesFromNode={setSelectedVehiclesFromNode}
          vehicleStatus={vehicleStatus}
          setVehicleStatus={setVehicleStatus}
          setAllMarkersVisible={setAllMarkersVisible}
        />
      )}
    </div>
  );
}