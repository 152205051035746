import React, { useRef } from "react";
import { Marker, Tooltip, Popup } from 'react-leaflet';
import L from 'leaflet';
import settings from "../../../../utils/config";

function getDivIcon(speed, maxSpeed, direction) {
    const iconHtml = `
        <div style="
            transform: rotate(-${direction}deg);
            width: 16px;
            height: 16px;
            background-image: url(${speed > maxSpeed ? `${settings.vehicleIconUrl}/images/routearrowered.png` : `${settings.vehicleIconUrl}/images/routearrowe.png`});
            background-size: contain;
        ">
        </div>
    `;

    return L.divIcon({
        className: '',
        html: iconHtml,
        iconSize: [16, 16],
        iconAnchor: [8, 8],
        popupAnchor: [2, -3],
        tooltipAnchor: [0, 0]
    });
}

function formatTimestamp(timestamp) {
    const date = new Date(timestamp.replace("T", " "));
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("el-GR", options);

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const period = hours >= 12 ? "μμ" : "πμ";
    hours = hours % 12 || 12;

    return `${formattedDate} ${hours}:${minutes}:${seconds} ${period}`;
}

function PopupContent({ timestamp, speed }) {
    return (
        <div className="popup-container text-center fs-10">
            <div>{formatTimestamp(timestamp)}</div>
            <div>{parseInt(speed)} km/h</div>
        </div>
    );
}

export default function RoutePoint({ marker, index }) {
    const markerRefs = useRef([]);

    return (
        <Marker
            position={[parseFloat(marker.Latitude), parseFloat(marker.Longitude)]}
            icon={getDivIcon(marker.Speed, marker.MaxSpeed, marker.Direction)}
            key={index}
            ref={(ref) => markerRefs.current[index] = ref}
            eventHandlers={{
                mouseover: () => {
                    if (markerRefs.current[index]) {
                        markerRefs.current[index].closePopup();
                        markerRefs.current[index].openTooltip();
                    }
                },
                mouseout: () => {
                    if (markerRefs.current[index])
                        markerRefs.current[index].closeTooltip();
                },
                click: () => {
                    if (markerRefs.current[index]) {
                        markerRefs.current[index].closeTooltip();
                        markerRefs.current[index].openPopup();
                    }
                }
            }}
        >
            <Popup>
                <PopupContent 
                    timestamp={marker.sdate.replace("T", " ")} 
                    speed={marker.Speed} 
                />
            </Popup>
            <Tooltip direction="top" offset={[0, -7]}>
                <PopupContent 
                    timestamp={marker.sdate.replace("T", " ")} 
                    speed={marker.Speed} 
                />
            </Tooltip>
        </Marker>
    );
}