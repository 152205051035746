import { useEffect, useState, useMemo } from "react";
import {Tree} from 'react-arborist';
import formatTreeViewData from "../../../utils/formatTreeViewData.js";
import TreeNode from "./TreeNode.js";

export default function TreeView({ fleetData, searchTerm, filters, selectedVehicles, setSelectedVehicles, vehicleStatus, setVehicleStatus, setAllMarkersVisible }) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [selectedVehiclesFromNode, setSelectedVehiclesFromNode] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formattedData = useMemo(() => {
    return formatTreeViewData(fleetData, filters);
  }, [fleetData, filters, selectedVehicles, setVehicleStatus]);

  return (
    <div className="tree-container fs-13">
        <Tree
          data={formattedData}
          width={380}
          disableDrag= {true}
          height={windowHeight - 100}
          indent={11}
          rowHeight={33}
          overscanCount={1}
          paddingBottom={10}
          padding={5}
          searchTerm={searchTerm}
          searchMatch={(node, term) => node.data.name.toLowerCase().includes(term.toLowerCase())}
        >
          {props =>
            <TreeNode
              {...props}
              filters={filters}
              selectedVehicles={selectedVehicles}
              setSelectedVehicles={setSelectedVehicles}
              selectedVehiclesFromNode={selectedVehiclesFromNode}
              setSelectedVehiclesFromNode={setSelectedVehiclesFromNode}
              vehicleStatus={vehicleStatus}
              setVehicleStatus={setVehicleStatus}
              setAllMarkersVisible={setAllMarkersVisible}
            />
          }
        </Tree>
    </div>
  );
}
