import Cookies from 'js-cookie';
import settings from '../config.js';
import setCookie from '../setCookie.js';

export default async function refreshJwtAccessToken() {
    const jwtIsAboutToExpire = () => {
        let accessToken = Cookies.get('jwtAccessToken');
        if (accessToken) {
            const [header, payload, signature] = accessToken.split('.');
            if (payload) {
                const decodedPayload = JSON.parse(atob(payload));
                const expirationTimestamp = decodedPayload.exp;
                const expirationDate = new Date(expirationTimestamp * 1000);

                const currentDateTime = new Date();
                currentDateTime.setMinutes(currentDateTime.getMinutes() + 3);
                if (expirationDate < currentDateTime)
                    return true;
                else
                    return false;
            }
        }

        return true;
    };

    if (jwtIsAboutToExpire()) {
        const response = await fetch(
            `${settings.panopticumWebApiAuthUrl}/api/jwtAccessToken`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
                }
            }
        );

        const data = await response.json();
        setCookie("jwtAccessToken", data.jwtAccessToken, true);
    }
};
