import settings from '../config.js';
import Cookies from 'js-cookie';

export default async function poiTrafficStatistic(latitude, longitude, isPoi, poiID){
    const params = new URLSearchParams({
        latitude,
        longitude,
        isPoi,
        poiID
    });

    const response = await fetch(
        `${settings.panopticumWebApiAuthUrl}/api/poiTrafficStatistic?${params}`,
        {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwtAccessToken')}`
            }
        }
    );

    return await response.json();
}