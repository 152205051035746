import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popup } from 'react-leaflet';
import { Modal, Button, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import settings from '../../../../utils/config';
import poiTrafficStatistic from '../../../../utils/api/poiTrafficStatistic';
import insertPoiApi from '../../../../utils/api/insertPoi';

const trafficStatistics = async (
    latitude, longitude, description, startHour, endHour, isPoi, poiId, index, setShowTrafficStatisticModal, setStatistics, setLoading
) => {
    setShowTrafficStatisticModal(true);
    setLoading(true);
    const data = await poiTrafficStatistic(latitude, longitude, isPoi, poiId);
    const statistics = {
        ...data,
        description,
        startHour,
        endHour,
        index
    };
    setStatistics(statistics);
    setLoading(false);
};

function getStationStartTimeAndDuration(startHour, endHour) {
    const stationArrivalTime = startHour.split("T");
    var datefrom = startHour;
    datefrom = datefrom.replace("T"," ");
    var dateto = endHour;
    dateto = dateto.replace("T"," ");
    return `${stationArrivalTime[1]} (${calculateTimeDifference(datefrom, dateto)})`;
}

function calculateTimeDifference(dateFrom,dateTo){
    var diffMinutes = parseInt((new Date(setZeroSeconds(dateTo)) - new Date(setZeroSeconds(dateFrom)))/60000);
    if(diffMinutes > 60){
        var hours = parseInt(diffMinutes/60);
        var minutes = diffMinutes%60;
        if (hours > 1)
            return hours + " Ώρες " + minutes + " Λεπτά";
        else
            return hours + " Ώρα " + minutes + " Λεπτά";
    }
    else
        return  diffMinutes + " Λεπτά";
}

function setZeroSeconds(datetime){
    var splitDatetime = datetime.split(":");
    return splitDatetime[0] + ":" + splitDatetime[1] + ":00";
}

const insertPoi = (latitude, longitude, address, setShowInsertPoiModal, setPoiInfo) => {
    setShowInsertPoiModal(true);
    const poiInfo = {
        latitude,
        longitude,
        address,
        stationName: "",
        distance: 50
    };

    setPoiInfo(poiInfo);
}

export default function StopPopup({ selectedVehicle, fetchStops, marker }) {
    const navigate = useNavigate();
    const [showTrafficStatisticModal, setShowTrafficStatisticModal] = useState(false);
    const [showInsertPoiModal, setShowInsertPoiModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState(null);
    const [poiInfo, setPoiInfo] = useState(null);
    const [validated, setValidated] = useState(false);
    const [mobileMode, setMobileMode] = useState(false);

    const handleCloseTrafficStatisticModal = () => {
        setShowTrafficStatisticModal(false);
        setStatistics(null);
    };

    const handleCloseInsertPoiModal = () => {
        setShowInsertPoiModal(false);
        setPoiInfo(null);
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setPoiInfo({
          ...poiInfo,
          [e.target.name]: value
        });
    };

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setShowInsertPoiModal(true);            
        } 
        else {
            event.preventDefault();
            setLoading(true);
            try {
                const stationData = {
                    PositionName: poiInfo.stationName,
                    Latitude: poiInfo.latitude,
                    Longitude: poiInfo.longitude,
                    MinDistance: parseInt(poiInfo.distance)
                  };

                await insertPoiApi(stationData);
                fetchStops(selectedVehicle.value);

            } catch (error) {
                navigate('/error');
            }
            finally {
                setLoading(false);
                handleCloseInsertPoiModal();
            }
        }

        setValidated(true);
    };

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 760px)');
        const handleMediaQueryChange = (event) => {
            setMobileMode(event.matches);
        };

        handleMediaQueryChange(mediaQuery);
        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);

    return (
        <>
            <Popup>
                <div className='stationPopupContainer'>
                    {mobileMode && (
                        <>
                            <div className='text-center fs-12 pointer stationTooltip'>
                                <div>{marker.StartHourText} - {marker.EndHourText}</div>
                                <div>{marker.Description}</div>
                                <div>{marker.NextDistance} km</div>
                            </div>
                            <div className='horizontal-line'></div>
                        </>
                    )}
                    <div
                        className='text-center p-2 fs-13 pointer stationTooltip'
                        onClick={() => trafficStatistics(
                            marker.Latitude,
                            marker.Longitude,
                            marker.Description,
                            marker.StartHour,
                            marker.EndHour,
                            marker.isPoi,
                            marker.PoiID,
                            marker.index,
                            setShowTrafficStatisticModal,
                            setStatistics,
                            setLoading
                        )}
                    >
                        <img
                            className="w-15 mt-5px"
                            src={`${settings.vehicleIconUrl}/images/chart.png`}
                            alt='Επισκεψιμότητα'
                        /> Επισκεψιμότητα
                    </div>
                    {!marker.isPoi && (
                        <>
                            <div className='horizontal-line'></div>
                            <div 
                                className='text-center p-2 fs-13 pointer stationTooltip'
                                onClick={() => insertPoi(
                                    marker.Latitude,
                                    marker.Longitude,
                                    marker.Description,
                                    setShowInsertPoiModal,
                                    setPoiInfo
                                )}
                            >
                                <img
                                    className="w-15 mt-5px"
                                    src={`${settings.vehicleIconUrl}/images/pin.png`}
                                    alt='Eισαγωγή σημείου'
                                /> Eισαγωγή σημείου
                            </div>
                        </>
                    )}
                </div>
            </Popup>

            <Modal size='sm' show={showTrafficStatisticModal} onHide={handleCloseTrafficStatisticModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='fs-18'>Στατιστικά Επισκεψιμότητας</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Φόρτωση...</span>
                            </Spinner>
                        </div>
                    ) : (
                        statistics ? (
                            <div className='fs-14'>
                                <div className="station-info">
                                    {statistics.index}.{statistics.description}
                                    <br />
                                    {getStationStartTimeAndDuration(statistics.startHour, statistics.endHour)}
                                </div>
                                <div className="pois-container">
                                    <div className="m-5px">Τελευταία Επίσκεψη</div>
                                    <div className="pois-info">{statistics.LastVisit} Όχημα: {statistics.TruckSign}</div>
                                </div>
                                <div className="pois-container">
                                    <div className="m-5px">
                                        Αριθμός Επισκέψεων<br /><span className="small-info-msg">(τον τελευταίο μήνα)</span>
                                    </div>
                                    <div className="pois-info">{statistics.VisitNumber}</div>
                                </div>
                                <div className="pois-container">
                                    <div className="m-5px">
                                        Μέσος Χρόνος Επίσκεψης<br /><span className="small-info-msg">(τον τελευταίο μήνα)</span>
                                    </div>
                                    <div className="pois-info">{statistics.AverageVisitDuration} λεπτά</div>
                                </div>
                            </div>
                        ) : (
                            <p>Δεν υπάρχουν διαθέσιμα δεδομένα.</p>
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseTrafficStatisticModal}>
                        Κλείσιμο
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size='sm' show={showInsertPoiModal} onHide={handleCloseInsertPoiModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='fs-18'>Εισαγωγή σημείου</Modal.Title>
                </Modal.Header>
                {poiInfo && (
                    <>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Modal.Body>
                                    <Row>
                                        <InputGroup hasValidation>
                                            <FloatingLabel label="Ονομασία" className="mb-2">
                                            <Form.Control type="text" name="stationName" placeholder="Ονομασία" autoComplete="off" value={poiInfo.stationName} onChange={handleChange} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please fill out the field.
                                            </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup hasValidation>
                                            <FloatingLabel label="Latitude" className="mb-2">
                                            <Form.Control type="text" name="latitude" placeholder="Latitude" autoComplete="off" defaultValue={poiInfo.latitude} readOnly required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please fill out the field.
                                            </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup hasValidation>
                                            <FloatingLabel label="Longitude" className="mb-2">
                                            <Form.Control type="text" name="longitude" placeholder="Longitude" autoComplete="off" defaultValue={poiInfo.longitude} readOnly required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please fill out the field.
                                            </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup hasValidation>
                                            <FloatingLabel label="Διεύθυνση" className="mb-2">
                                            <Form.Control type="text" name="address" placeholder="Διεύθυνση" autoComplete="off" defaultValue={poiInfo.address} required/>
                                            <Form.Control.Feedback type="invalid">
                                                Please fill out the field.
                                            </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Row>
                                    <Row>
                                        <InputGroup hasValidation>
                                            <FloatingLabel label="Ελάχιστη απόσταση" className="mb-2">
                                                <Form.Select name="distance" required onChange={handleChange}>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="150">150</option>
                                                    <option value="200">200</option>
                                                    <option value="250">250</option>
                                                    <option value="300">300</option>
                                                    <option value="350">350</option>
                                                    <option value="400">400</option>
                                                    <option value="450">450</option>
                                                    <option value="500">500</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a valid distance.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </InputGroup>
                                    </Row>
                            </Modal.Body>
                            {loading && (
                                <div className="text-center mb-3">
                                    <Spinner animation="border" />
                                </div>
                            )}
                            <Modal.Footer>
                                <Button type="submit" variant="success">
                                    Αποθήκευση
                                </Button>
                            </Modal.Footer>
                        </Form>
                    </>
                )}
            </Modal>
        </>
    );
}