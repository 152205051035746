import settings from '../utils/config.js';
import {LayersControl, TileLayer} from 'react-leaflet';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

const OsmMapLayer = () => {
    return <TileLayer 
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
};

export default function MapLayers({activeLayer}) {
    const googleApiKey = settings.googleMapsApiKey;

    return (
        <>
            {settings.showGoogleMapsLayers ?
                <LayersControl position="topright">
                    <LayersControl.BaseLayer checked={activeLayer === 'Osm'} name="Osm">
                        <OsmMapLayer/>
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked={activeLayer === 'Roadmap'} name="Roadmap">
                        <ReactLeafletGoogleLayer apiKey={googleApiKey} type={'roadmap'} />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked={activeLayer === 'Satellite'} name="Satellite">
                        <ReactLeafletGoogleLayer apiKey={googleApiKey} type={'satellite'} />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked={activeLayer === 'Hybrid'} name="Hybrid">
                        <ReactLeafletGoogleLayer apiKey={googleApiKey} type={'hybrid'} />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked={activeLayer === 'Traffic'} name="Traffic">
                        <ReactLeafletGoogleLayer apiKey={googleApiKey} googleMapsAddLayers={[{ name: 'TrafficLayer' }]} />
                    </LayersControl.BaseLayer>
                    <LayersControl.BaseLayer checked={activeLayer === 'Transit'} name="Transit">
                        <ReactLeafletGoogleLayer apiKey={googleApiKey} googleMapsAddLayers={[{ name: 'TransitLayer' }]} />
                    </LayersControl.BaseLayer>
                </LayersControl> :
                <OsmMapLayer/>
            }
        </>
    );
}